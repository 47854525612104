import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

import Header from './Header'
import Address from './Address'
import Apartments from './Apartments'
import Buttons from './Buttons'

function Main({access, setAccess, refresh, setRefresh, requestUser, setRequestUser, setRefreshRequired, apartments, setApartments, nextPage, setNextPage, stop, setStop, firstPage}) {
  return (
    <>
      <Header />
      <Address access={access}
               setAccess={setAccess}
               refresh={refresh}
               setRefresh={setRefresh}
               requestUser={requestUser}
               setRequestUser={setRequestUser}
               setRefreshRequired={setRefreshRequired}/>
      <Apartments access={access}
                  setAccess={setAccess}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  requestUser={requestUser}
                  setRequestUser={setRequestUser}
                  setRefreshRequired={setRefreshRequired}
                  apartments={apartments}
                  setApartments={setApartments}
                  nextPage={nextPage}
                  setNextPage={setNextPage}
                  stop={stop}
                  setStop={setStop}
                  firstPage={firstPage}/>
      <Buttons access={access}
               setAccess={setAccess}
               refresh={refresh}
               setRefresh={setRefresh}
               requestUser={requestUser}
               setRequestUser={setRequestUser}
               setRefreshRequired={setRefreshRequired}
               apartments={apartments}
               setApartments={setApartments}/>
    </>
  );
}
export default Main;
