import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'

function Address({access, setAccess, refresh, setRefresh, requestUser, setRequestUser, setRefreshRequired}) {
  return (
    <div className="location-list">
      <div className="location">
        Все квартиры <img src={require('./images/arrow-down.svg').default} alt=""/>
      </div>
    </div>
  );
}
export default Address;
