import React, { useState, useEffect } from 'react';
import LazyLoad from "react-lazyload";
import { Link, useNavigate } from 'react-router-dom'

import Service from './../Service'
const service = new Service()

function AddApartment({setNext, access, setAccess, refresh, setRefresh, requestUser, setRequestUser, setRefreshRequired, apartments, setApartments}) {

  const [address, setAddress] = useState(null)
  const [floor, setFloor] = useState(null)
  const [number, setNumber] = useState(null)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    if (access){
      if (loading){
        service.addApartment({address: address, floor: floor, number: number}, {'Authorization': `Bearer ${access}`}).then(function (result) {
          if (result.status === 200){
            setRefreshRequired(false);
            setApartments([...apartments, result.data.result]);
            setLoading(false);
            setNext(false);
            navigate('/apartment/'+result.data.result.id);
          }else if (result.status === 401){
            setRefreshRequired(true);
          }
        });
      }
    }
  }, [access, loading])

  const changeInput = (e) => {
    if (e.target.value){
      e.target.parentNode.children[1].style.transform = "translateY(-12px)";
      e.target.parentNode.children[1].style.fontSize = "10px";
    }else{
      e.target.parentNode.children[1].style.transform = "translateY(0)";
      e.target.parentNode.children[1].style.fontSize = "14px";
    }

    if (e.target.name === 'address'){
      setAddress(e.target.value)
    }else if (e.target.name === 'floor'){
      setFloor(e.target.value)
    }else if (e.target.name === 'number'){
      setNumber(e.target.value)
    }
  }

  const focusInput = (e) => {
    e.target.parentNode.children[0].focus()
  }

  const back = (e) => {
    e.target.parentNode.parentNode.style.transform = "translateY(100vh)";
    setTimeout(() => {setNext(false)}, 300)
  }

  const save = (e) => {
    setLoading(true)
  }

  return (
    <>
      <div className="add-publication bottom">
        <div className="addHeader">
          <div className="cancel" onClick={back}>
            Отмена
          </div>
          <div className="text">
            Добавить квартиру
          </div>
          <div className="next" onClick={save}>
            Сохранить
          </div>
        </div>
        <div className="settings">
          <div className="block-form">
            <div className="inputs">

              <div className="input">
                <textarea name="address" onChange={changeInput}></textarea>
                <div className="placeholder" onClick={focusInput}>
                  Адрес дома
                </div>
              </div>

              <div className="input">
                <input type="number" name="floor" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Этаж
                </div>
              </div>

              <div className="input">
                <input type="number" name="number" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Номер квартиры
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddApartment;
