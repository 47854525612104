import React, { useState, useEffect, useRef } from 'react';
import LazyLoad from "react-lazyload";
import { Link, useParams, useNavigate } from 'react-router-dom'
import ActionsPost from '../Actions/ActionsPost'
import Service from './../Service'
const service = new Service()

function ProfilePosts({access, setAccess, refresh, setRefresh, setRequestUser, requestUser, setRefreshRequired}) {

  const navigate = useNavigate();
  const { username } = useParams();

  const [posts, setPosts] = useState([])
  const [nextPage, setNextPage] = useState("/api/posts/user/"+ username +"?page=1")
  const [loading, setLoading] = useState(true)

  const [postLikedId, setPostLikedId] = useState(null)
  const [postSavedId, setPostSavedId] = useState(null)

  const [stop, setStop] = useState(false)
  const [scrollToPost, setScrollToPost] = useState(false)
  const firstPage = "/api/posts/user/"+ username +"?page=1"

  const [showActionsPost, setShowActionsPost] = useState(false)
  const [selectPostId, setSelectPostId] = useState(null)

  useEffect(() => {
    if (access){
      if (!stop){
        if (loading){
          service.getPostsByURL(nextPage, {'Authorization': `Bearer ${access}`}).then(function (result) {
            if (result.status === 200){
              setRefreshRequired(false);
              setLoading(false);
              if (result.data.nextlink === firstPage){
                setStop(true)
              }
              setPosts([...posts, ...result.data.result]);
              setNextPage(result.data.nextlink);
              setScrollToPost(true);
            }else if (result.status === 401){
              setRefreshRequired(true);
            }
          });
        }
      }
    }
  }, [access, stop, loading])

  useEffect(() => {
    if (access){
      if (postLikedId){
        service.likePost(postLikedId, {'Authorization': `Bearer ${access}`}).then(function(result) {
          if (result.status === 401){
            setRefreshRequired(true);
          }else{
            setRefreshRequired(false);
            setPostLikedId(null);
          }
        });
      }
    }
  }, [access, postLikedId])

  useEffect(() => {
    if (access){
      if (postSavedId){
        service.savePost(postSavedId, {'Authorization': `Bearer ${access}`}).then(function(result) {
          if (result.status === 401){
            setRefreshRequired(true);
          }else{
            setRefreshRequired(false);
            setPostSavedId(null);
          }
        });
      }
    }
  }, [access, postSavedId])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return function () {
      document.removeEventListener('scroll', scrollHandler);
    }
  }, [])

  useEffect(() => {
    if (scrollToPost) {
      try {
        document.querySelector(window.location.hash).scrollIntoView({ block: "center" });
      } catch (e) {
        console.log(e)
      }

    }
  }, [scrollToPost])

  const scrollHandler = (e) => {
    if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100){
      setLoading(true);
    }
  }

  const handleScroll = event =>{
    let count = event.target.dataset.count;
    let scrollLeft = (event.target.scrollLeft - 30) / count;
    event.target.offsetParent.children[1].children[0].style.transform = 'translateX('+ scrollLeft +'px)';
  }

  const showAll = (description, event) => {
    event.target.offsetParent.children[1].innerHTML = description
  }

  const addLike = (id, post, e) => {
    setPostLikedId(id);
    if (e.target.dataset.liked == "false"){
      e.target.src = require('../Main/images/like-active.svg').default;
      e.target.dataset.liked = "true";
      post.likes_count = post.likes_count + 1;
    }else{
      e.target.src = require('../Main/images/like.svg').default;
      e.target.dataset.liked = "false";
      post.likes_count = post.likes_count - 1;
    }
    document.querySelector('#likes-count'+id).innerHTML = `Нравится: ${post.likes_count}`;
  }

  const savePost = (id, post, e) => {
    setPostSavedId(id);
    if (e.target.dataset.saved == "false"){
      e.target.src = require('../Main/images/save-active.svg').default;
      e.target.dataset.saved = "true";
    }else{
      e.target.src = require('../Main/images/save.svg').default;
      e.target.dataset.saved = "false";
    }
  }

  const showMore = (e) => {
    if (requestUser.username === e.target.dataset.username){
      setShowActionsPost(true);
      setSelectPostId(e.target.dataset.postid);
    }
  }

  const share = (e) => {
    if (navigator.share) {
      navigator.share({
        url: e.target.dataset.share,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
    } else {
      alert('Share not supported on this browser, do it the old way.');
    }
  }

  return (
    <>
    <div className="postsHeader">
      <div className="back" onClick={() => navigate(-1)}>
        <img src={require('../Main/images/back.svg').default} alt=""/>
      </div>
      <div className="title">
        {username}
      </div>
      <div className="share">
      </div>
    </div>
    <div className="posts user">
      {posts?.map(c =>
        <div className="post" id={'post'+c.id} key={c.id}>
          <div className="postHeader">
            <div className="storie">
              <Link to={"/"+c.user.username}>
                <LazyLoad once className="avatar">
                  <img src={c.user.avatar ? c.user.avatar : "http://backend.idlpro.ru/media/avatars/non/non-avatar.svg"} alt={c.user.name} />
                </LazyLoad>
              </Link>
            </div>
            <div className="user">
              <div><Link to={"/"+c.user.username}>{c.user.username}</Link></div>
              <div></div>
            </div>
            {requestUser.username === c.user.username ?
              <div className="more" onClick={showMore} data-username={c.user.username} data-postid={c.id}>
                <img src={require('../Main/images/more.svg').default} alt=""/>
              </div>
            : null}
          </div>
          <div className="media">
            <div className="files" onScroll={handleScroll} data-count={c.items.length}>
              {c.items?.map(i =>
              <LazyLoad once className="file" placeholder={<img src={i.placeholder ? i.placeholder : i.file} alt={c.user.name} key={i.pk} />} key={i.id}>
                <img src={i.file} alt={c.user.name} key={i.pk} />
              </LazyLoad>
            )}
            </div>
            {c.items.length > 1 ?
            <div className="scrollbar">
              <div className="active" style={{width: (100 / c.items.length) + '%'}}></div>
            </div> : ''
            }
          </div>
          <div className="actions">
            <div className="list">
              {c.is_liked? <img src={require('../Main/images/like-active.svg').default} alt="" onClick={addLike.bind(this, c.id, c)} data-liked="true"/>
              : <img src={require('../Main/images/like.svg').default} alt="" onClick={addLike.bind(this, c.id, c)} data-liked="false"/>}
              <Link to={"/p/"+c.id+"/comments"}>
              <img src={require('../Main/images/comment.svg').default} alt="" />
              </Link>
              <img src={require('../Main/images/share.svg').default} alt="" />
              <img src={require('../Main/images/share2.svg').default} alt="" onClick={share} data-share={window.location.origin + '/' + c.user.username + "/posts#post" + c.id}/>
              {c.is_saved? <img src={require('../Main/images/save-active.svg').default} alt="" onClick={savePost.bind(this, c.id, c)} data-saved="true" />
              : <img src={require('../Main/images/save.svg').default} alt="" onClick={savePost.bind(this, c.id, c)} data-saved="false" />}

            </div>
          </div>
          {c.description.length > 0
          ? <div className="desc">
              <div className="top">
                <div>
                  {c.user.username}
                </div>
                <div id={'likes-count'+c.id}>
                  Нравится: {c.likes_count}
                </div>
              </div>
              <div className="text" style={{whiteSpace: "pre-wrap"}}>
                {c.description.length > 60
                  ? <>{c.description.slice(0, 60)}... <span onClick={showAll.bind(this, c.description)}> ещё</span></>
                  : <>{c.description}</>
                }
              </div>
              <div className="bottom">
                <div>
                  {c.date}
                </div>
                <div>
                  <Link to={"/p/"+c.id+"/comments"}>
                  {c.comments_count > 0 ? <>Смотреть все комментарии ({c.comments_count})</> : <>Добавить комментарий</>}
                  </Link>
                </div>
              </div>
            </div>
          : <div className="desc">
              <div className="standart">
                <div id={'likes-count'+c.id}>
                  Нравится: {c.likes_count}
                </div>
                <div>
                  {c.date}
                </div>
              </div>
            </div>
          }
        </div>

      )}
    </div>
    {showActionsPost ? <ActionsPost access={access} setAccess={setAccess} refresh={refresh} setRefresh={setRefresh} requestUser={requestUser} setRequestUser={setRequestUser} setRefreshRequired={setRefreshRequired} postid={selectPostId} setShowActionsPost={setShowActionsPost} posts={posts} setPosts={setPosts}/> : null}
    </>
  );
}
export default ProfilePosts;
