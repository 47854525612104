import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'

import AddApartment from './AddApartment'

import Service from '../Service'
const service = new Service();

function Apartments({access, setAccess, refresh, setRefresh, requestUser, setRequestUser, setRefreshRequired, apartments, setApartments, nextPage, setNextPage, stop, setStop, firstPage}) {

  const [next, setNext] = useState(false)

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true)
  const [display, setDisplay] = useState('list')
  const [floor, setFloor] = useState('all')

  const [floors, setFloors] = useState([])

  useEffect(() => {
    if (access){
      if (!stop){
        if (loading){
          service.getApartments(nextPage, {'Authorization': `Bearer ${access}`}).then(function (result) {
            if (result.status === 200){
              setRefreshRequired(false);
              setLoading(false);
              if (result.data.nextlink === firstPage){
                setStop(true)
              }
              setApartments([...apartments, ...result.data.result]);
              setNextPage(result.data.nextlink);
            }else if (result.status === 401){
              setRefreshRequired(true);
            }
          });
        }
      }
    }
  }, [access, stop, loading])

  useEffect(() => {
    if (access){
      if (floors.length === 0){
        service.getFloors({'Authorization': `Bearer ${access}`}).then(function (result) {
          if (result.status === 200){
            setRefreshRequired(false);
            setFloors(result.data.result)
          }else if (result.status === 401){
            setRefreshRequired(true);
          }
        });
      }
    }
  }, [access, floors])

  return (
    apartments.length > 0 ?
      <div className="block">
        <div className="block-top">
          <div className="categories">
            <div className={floor === 'all' ? "active" : ""} onClick={() => setFloor('all')}>
              Все этажи
            </div>
            {floors?.map((f, idx) =>
              <div className={floor === f.number ? "active" : ""} onClick={() => setFloor(f.number)} key={idx}>
                {f.number} этаж
              </div>
            )}
          </div>
          <div className="display">
            <div className={display === 'list' ? "active display1" : "display1"} onClick={() => setDisplay('list')}>
              {display === 'list'
              ? <img src={require('./images/display1-active.svg').default} alt=""/>
              : <img src={require('./images/display1.svg').default} alt=""/>
              }
            </div>
            <div className={display === 'calendar' ? "active display2" : "display2"} onClick={() => setDisplay('calendar')}>
              {display === 'calendar'
              ? <img src={require('./images/display2-active.svg').default} alt=""/>
              : <img src={require('./images/display2.svg').default} alt=""/>
              }
            </div>
          </div>
        </div>
        {display === 'list' &&
        <div className="apartments-list">
          {apartments?.map((apartment, idx) =>
            floor === 'all' ?
              <div className={!apartment.active
                             ? "apartment occupied"
                             : apartment.status === 'Свободна'
                               ? "apartment"
                               : apartment.status === 'Занята' ? "apartment occupied"
                                 : apartment.status === 'Бронь' && "apartment booked"
                             } data-floor={apartment.floor.number} key={idx} onClick={() => navigate('/apartment/'+apartment.id)}>
                <div className="info">
                  <div className="number">
                    {apartment.number}
                  </div>
                  <div className="status">
                    {!apartment.active ? 'Временно недоступна' : apartment.status}
                  </div>
                </div>
              </div>
            :
              floor === apartment.floor.number &&
              <div className={!apartment.active
                             ? "apartment occupied"
                             : apartment.status === 'Свободна'
                               ? "apartment"
                               : apartment.status === 'Занята' ? "apartment occupied"
                                 : apartment.status === 'Бронь' && "apartment booked"
                             } data-floor={apartment.floor.number} key={idx} onClick={() => navigate('/apartment/'+apartment.id)}>
                <div className="info">
                  <div className="number">
                    {apartment.number}
                  </div>
                  <div className="status">
                    {!apartment.active ? 'Временно недоступна' : apartment.status}
                  </div>
                </div>
              </div>
          )}
        </div>}

        {display === 'calendar' &&
        <div className="apartments-calendar">
          <div className="apartments br">
            <div className="search">
              <input type="text" name="search" value=""/>
              <div>
                <img src={require('./images/search-input.svg').default} alt=""/>Поиск
              </div>
            </div>
            <div className="list">
            {apartments?.map((apartment, idx) =>
              <div className="apartment" key={idx}>
                Кв. №{apartment.number}
              </div>
            )}
            </div>
          </div>
          <div className="calendar">
            <div className="mounth">
              <div>
                <img src={require('./images/arrow-grey-left.svg').default} alt=""/>
              </div>
              <div>
                Февраль
              </div>
              <div>
                <img src={require('./images/arrow-grey-right.svg').default} alt=""/>
              </div>
            </div>
            <div className="overflow">
              <div className="days">
                <div>
                  Вт<br/>
                  1
                </div>
                <div>
                  Ср<br/>
                  2
                </div>
                <div>
                  Чт<br/>
                  3
                </div>
                <div>
                  Пт<br/>
                  4
                </div>
                <div>
                  Сб<br/>
                  5
                </div>
                <div>
                  Вс<br/>
                  6
                </div>
                <div>
                  Пн<br/>
                  7
                </div>
                <div>
                  Вт<br/>
                  8
                </div>
                <div>
                  Ср<br/>
                  9
                </div>
                <div>
                  Чт<br/>
                  10
                </div>
                <div>
                  Пт<br/>
                  11
                </div>
                <div className="weekend">
                  Сб<br/>
                  12
                </div>
                <div className="weekend">
                  Вс<br/>
                  13
                </div>
                <div>
                  Пн<br/>
                  14
                </div>
                <div>
                  Вт<br/>
                  15
                </div>
                <div>
                  Ср<br/>
                  16
                </div>
                <div>
                  Чт<br/>
                  17
                </div>
                <div>
                  Пт<br/>
                  18
                </div>
                <div>
                  Сб<br/>
                  19
                </div>
                <div>
                  Вс<br/>
                  20
                </div>
                <div>
                  Пн<br/>
                  20
                </div>
                <div>
                  Вт<br/>
                  21
                </div>
                <div>
                  Ср<br/>
                  22
                </div>
                <div>
                  Чт<br/>
                  23
                </div>
                <div>
                  Пт<br/>
                  24
                </div>
                <div>
                  Сб<br/>
                  25
                </div>
                <div>
                  Вс<br/>
                  26
                </div>
                <div>
                  Пн<br/>
                  27
                </div>
                <div>
                  Вт<br/>
                  28
                </div>
              </div>
              <div className="calendar-items">
              {apartments?.map((apartment, idx) =>
                <div className="calendar-item" key={idx}>
                  {idx === 0 ?
                  <div className="cell">
                    <div className="occupied" data-days="5" style={{width: 60 * 5 + 4 * 1 + 'px'}}>Ромаданов Владислав Константинович</div>
                  </div>
                  :<div className="cell">
                  </div>
                  }
                  {idx === 1 ?
                  <div className="cell">
                    <div className="booked" data-days="10" style={{width: 60 * 10 + 9 * 1 + 'px'}}>Агафонов Егор Андреевич</div>
                  </div>
                  :<div className="cell">
                  </div>
                  }
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                  <div className="cell">
                  </div>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    :
    <>
    <div className="null">
      <div className="apartments_null">Список квартир пуст</div>
      <div className="button" onClick={() => setNext(true)}>Добавить</div>
    </div>
    {next ?
      <AddApartment setNext={setNext}
                    access={access}
                    setAccess={setAccess}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    requestUser={requestUser}
                    setRequestUser={setRequestUser}
                    setRefreshRequired={setRefreshRequired}
                    apartments={apartments}
                    setApartments={setApartments}/>
    : null}
    </>
  );
}
export default Apartments;
