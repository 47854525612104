import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from "react-router";
import InputMask from 'react-input-mask'

import Buttons from './Buttons'
import CropAvatar from '../Crop/CropAvatar'
import Back from './Back'

import Service from '../Service'
const service = new Service();

function AddGuest({access, setAccess, refresh, setRefresh, requestUser, setRequestUser, setRefreshRequired, apartments, setApartments}) {

  const [apartment, setApartment] = useState(null)
  const { apartment_id } = useParams()
  const [activeStatus, setActiveStatus] = useState('active')
  const [loading, setLoading] = useState(false)

  const [avatar, setAvatar] = useState(null);
  const [newAvatar, setNewAvatar] = useState({})
  const formData = new FormData();
  const [error, setError] = useState(null)
  const usernameregexp = /^[0-9a-z_.]+$/i;

  const navigate = useNavigate();

  var user = {}

  useEffect(() => {
    if (access){
      if (!apartment) {
        service.getApartment(apartment_id, {'Authorization': `Bearer ${access}`}).then(function (result) {
          if (result.status === 200){
            setRefreshRequired(false);
            setApartment(result.data.result);
            if (!result.data.result.active) {
              setActiveStatus('disactive')
            };

          }else if (result.status === 401){
            setRefreshRequired(true);
          }
        });
      }
    }
  }, [access, apartment])

  const changeInput = (e) => {
    if (e.target.name === 'telegram'){
      if ("@" === e.target.value[0]){
        e.target.value = e.target.value.replace(' ', '');
      }else{
        if (e.target.value){
          e.target.value = "@"+e.target.value.replace(' ', '');
        }else{
          e.target.value = e.target.value.replace(' ', '');
        }
      }
    }else if (e.target.name === 'username'){
      if (error === 'username'){
        setError(null)
      }
      if (e.target.value){
        console.log(usernameregexp.test(e.target.value))
        if (usernameregexp.test(e.target.value)){
          e.target.value = e.target.value.substr(0,30);
        }else if (e.target.value[e.target.value.length - 1] === ' '){
          if (e.target.value.slice(-2) === '_ '){
            e.target.value = e.target.value.replace('_ ', '.').substr(0,30);
          }else{
            e.target.value = e.target.value.replace(' ', '_').substr(0,30);
          }
        }else{
          e.target.value = e.target.value.substr(0,e.target.value.length - 1);
        };
      }else{
        e.target.value = e.target.value.substr(0,30);
      }
    }else{
      e.target.value = e.target.value;
    }

    if (e.target.value){
      e.target.parentNode.children[1].style.transform = "translateY(-12px)";
      e.target.parentNode.children[1].style.fontSize = "10px";
    }else{
      e.target.parentNode.children[1].style.transform = "translateY(0)";
      e.target.parentNode.children[1].style.fontSize = "14px";
    }
  }

  const focusInput = (e) => {
    e.target.parentNode.children[0].focus()
  }

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result);
      }
      reader.onerror = reject;
      reader.readAsDataURL(file);
    })
  }

  const changeAvatar = async(e) => {
    let res = await readFile(e.target.files[0]);
    setAvatar(res);
    document.querySelector("input[name='new_avatar']").value = null;
  }

  const addGuest = (e) => {
    e.preventDefault();
    setLoading(true);
  }

  useEffect(() => {
    if (access){
      if (loading){
        newAvatar.avatar_blob && formData.append('files', newAvatar.avatar_blob);
        document.querySelector('[name="username"]').value && formData.append('username', document.querySelector('[name="username"]').value);
        document.querySelector('[name="name"]').value && formData.append('name', document.querySelector('[name="name"]').value);
        document.querySelector('[name="last_name"]').value && formData.append('last_name', document.querySelector('[name="last_name"]').value);
        document.querySelector('[name="middle_name"]').value && formData.append('middle_name', document.querySelector('[name="middle_name"]').value);
        document.querySelector('[name="sex"]').value && formData.append('sex', document.querySelector('[name="sex"]').value);
        document.querySelector('[name="birth_date"]').value && formData.append('birth_date', document.querySelector('[name="birth_date"]').value);
        document.querySelector('[name="phone"]').value && formData.append('phone', document.querySelector('[name="phone"]').value);
        document.querySelector('[name="telegram"]').value && formData.append('telegram', document.querySelector('[name="telegram"]').value);
        document.querySelector('[name="site"]').value && formData.append('site', document.querySelector('[name="site"]').value);
        document.querySelector('[name="sitecompany"]').value && formData.append('sitecompany', document.querySelector('[name="sitecompany"]').value);
        document.querySelector('[name="city"]').value && formData.append('city', document.querySelector('[name="city"]').value);
        document.querySelector('[name="street"]').value && formData.append('street', document.querySelector('[name="street"]').value);
        document.querySelector('[name="apartmentnumber"]').value && formData.append('apartmentnumber', document.querySelector('[name="apartmentnumber"]').value);
        document.querySelector('[name="birth_place"]').value && formData.append('birth_place', document.querySelector('[name="birth_place"]').value);
        document.querySelector('[name="number"]').value && formData.append('number', document.querySelector('[name="number"]').value);
        document.querySelector('[name="subdivision"]').value && formData.append('subdivision', document.querySelector('[name="subdivision"]').value);
        document.querySelector('[name="issue_date"]').value && formData.append('issue_date', document.querySelector('[name="issue_date"]').value);
        document.querySelector('[name="issued_by"]').value && formData.append('issued_by', document.querySelector('[name="issued_by"]').value);
        document.querySelector('[name="registration_address"]').value && formData.append('registration_address', document.querySelector('[name="registration_address"]').value);
        document.querySelector('[name="position"]').value && formData.append('position', document.querySelector('[name="position"]').value);
        document.querySelector('[name="company"]').value && formData.append('company', document.querySelector('[name="company"]').value);
        document.querySelector('[name="rental_period"]').value && formData.append('rental_period', document.querySelector('[name="rental_period"]').value);
        document.querySelector('[name="rent"]').value && formData.append('rent', document.querySelector('[name="rent"]').value);
        document.querySelector('[name="deposit"]').value && formData.append('deposit', document.querySelector('[name="deposit"]').value);
        document.querySelector('[name="date_start"]').value && formData.append('date_start', document.querySelector('[name="date_start"]').value);
        document.querySelector('[name="date_end"]').value && formData.append('date_end', document.querySelector('[name="date_end"]').value);
        document.querySelector('[name="booking_start"]').value && formData.append('booking_start', document.querySelector('[name="booking_start"]').value);
        document.querySelector('[name="booking_end"]').value && formData.append('booking_end', document.querySelector('[name="booking_end"]').value);
        document.querySelector('[name="people_count"]').value && formData.append('people_count', document.querySelector('[name="people_count"]').value);
        document.querySelector('[name="adults"]').value && formData.append('adults', document.querySelector('[name="adults"]').value);
        document.querySelector('[name="babies"]').value && formData.append('babies', document.querySelector('[name="babies"]').value);
        document.querySelector('[name="animals"]').value && formData.append('animals', document.querySelector('[name="animals"]').value);
        document.querySelector('[name="cleaning"]').value && formData.append('cleaning', document.querySelector('[name="cleaning"]').value);
        document.querySelector('[name="transfer"]').value && formData.append('transfer', document.querySelector('[name="transfer"]').value);

        service.addGuest(apartment_id, formData, {'Authorization': `Bearer ${access}`}).then(function(result){
          if (result.status === 200){
            navigate('/apartment/'+apartment_id);
            setLoading(false);
          }else if (result.status === 401){
            setRefreshRequired(true);
          }else if (result.status === 400){
            setLoading(false);
            if (result.data.error === 'username is taken by another user'){
              setError('username')
            }
          }else{
            setLoading(false);
          }
        })
      }
    }
  })

  return (
    <>
    {apartment ?
      <>
      <div className="location-list">
        <div className="location">
          {apartment.address.address}
        </div>
      </div>
      <div className="block">
        <div className="block-top">
          <div className="title">
            <span><div className={!apartment.active ? "red" : apartment.status === 'Свободна' ? "green" : apartment.status === 'Бронь' ? "yellow" : apartment.status === 'Занята' ? "red" : null}></div>Квартира №{apartment.number}</span>
          </div>
          <div className="share">
            <img src={require('./images/share3.svg').default} alt=""/>
          </div>
        </div>

        <form className="settings" method="post" onSubmit={addGuest}>
          <div className="block-form">
            <div className="inputs withavatar">
              <div className={error === 'username' ? "input single error" : "input single"}>
                <input type="text" name="username" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Имя пользователя
                </div>
              </div>
              <div className="input first">
                <input type="text" name="name" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Имя
                </div>
              </div>
              <div className="input">
                <input type="text" name="last_name" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Фамилия
                </div>
              </div>
              <div className="input">
                <input type="text" name="middle_name" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Отчество
                </div>
              </div>
              <div className="input">
                <select name="sex" onChange={changeInput} required>
                  <option value="">Не выбран</option>
                  <option value="Мужской">Мужской</option>
                  <option value="Женский">Женский</option>
                </select>
                <div className="placeholder" onClick={focusInput} style={{transform: "translateY(-12px)", fontSize: "10px"}}>
                  Пол
                </div>
              </div>
              <div className="input">
                <input type="date" name="birth_date" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Дата рождения
                </div>
              </div>
            </div>
            <div className="changeAvatar">
              <img src={newAvatar.avatar ? newAvatar.avatar : "https://backend.idlpro.ru/media/avatars/non/non-avatar.svg"} alt=""/>
              <div className="icon">
                <img src={require("../Main/images/camera.svg").default} alt=""/>
              </div>
              <input type="file" name="new_avatar" accept="image/*" onChange={changeAvatar}/>
            </div>
          </div>
          <div className="buttons">
            <div className="button">
              <img src={require('./images/passport.svg').default} alt=""/> Паспорт
            </div>
            <div className="button">
              <img src={require('./images/document.svg').default} alt=""/> Договор
            </div>
          </div>
          <div className="block-form">
            <div className="inputs">
              <div className="input">
                <InputMask maskPlaceholder="" mask="+7 999 999 99-99" type="tel" name="phone" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Телефон
                </div>
              </div>
              <div className="input">
                <input type="text" name="telegram" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Telegram @
                </div>
              </div>
              <div className="input">
                <input type="text" name="site" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Сайт
                </div>
              </div>
            </div>
          </div>

          <div className="block-form">
            <div className="inputs">
              <div className="input">
                <input type="text" name="city" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Город
                </div>
              </div>
              <div className="input">
                <input type="text" name="street" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Улица
                </div>
              </div>
              <div className="input">
                <input type="text" name="apartmentnumber" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Квартира
                </div>
              </div>
            </div>
          </div>

          <div className="block-form">
            <div className="inputs">
              <div className="input">
                <input type="text" name="birth_place" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Место рождения
                </div>
              </div>
              <div className="input">
                <input type="text" name="number" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Серия и номер
                </div>
              </div>
              <div className="input">
                <input type="text" name="subdivision" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Код подразделения
                </div>
              </div>
              <div className="input">
                <input type="date" name="issue_date" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Дата выдачи
                </div>
              </div>
              <div className="input">
                <textarea name="issued_by" onChange={changeInput} required></textarea>
                <div className="placeholder" onClick={focusInput}>
                  Кем выдан
                </div>
              </div>
              <div className="input">
                <textarea name="registration_address" onChange={changeInput} required></textarea>
                <div className="placeholder" onClick={focusInput}>
                  Адрес регистрации
                </div>
              </div>
            </div>
          </div>

          <div className="block-form">
            <div className="inputs">
              <div className="input">
                <input type="text" name="position" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Должность
                </div>
              </div>
              <div className="input">
                <input type="text" name="company" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Компания
                </div>
              </div>
              <div className="input">
                <input type="text" name="sitecompany" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Сайт
                </div>
              </div>
            </div>
          </div>

          <div className="block-form">
            <div className="inputs">
              <div className="input">
                <select name="rental_period" onChange={changeInput} required>
                  <option value="На длительный срок">На длительный срок</option>
                  <option value="Посуточно">Посуточно</option>
                </select>
                <div className="placeholder" onClick={focusInput} style={{transform: "translateY(-12px)", fontSize: "10px"}}>
                  Срок аренды
                </div>
              </div>
              <div className="input">
                <input type="text" name="rent" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Арендная плата
                </div>
              </div>
              <div className="input">
                <input type="text" name="deposit" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Залог
                </div>
              </div>
            </div>
          </div>

          <div className="block-form">
            <div className="inputs">
              <div className="input">
                <input type="date" name="date_start" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Дата заезда
                </div>
              </div>
              <div className="input">
                <input type="date" name="date_end" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Дата выезда
                </div>
              </div>
            </div>
          </div>

          <div className="block-form">
            <div className="inputs">
              <div className="input">
                <input type="date" name="booking_start" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Бронь от
                </div>
              </div>
              <div className="input">
                <input type="date" name="booking_end" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Бронь до
                </div>
              </div>
            </div>
          </div>

          <div className="block-form">
            <div className="inputs">
              <div className="input">
                <input type="text" name="people_count" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Кол-во человек
                </div>
              </div>
              <div className="input">
                <input type="text" name="adults" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Взрослые
                </div>
              </div>
              <div className="input">
                <input type="text" name="babies" onChange={changeInput} required/>
                <div className="placeholder" onClick={focusInput}>
                  Дети
                </div>
              </div>
              <div className="input">
                <select name="animals" onChange={changeInput} required>
                  <option value="Нет">Нет</option>
                  <option value="Есть">Есть</option>
                </select>
                <div className="placeholder" onClick={focusInput} style={{transform: "translateY(-12px)", fontSize: "10px"}}>
                  Животные
                </div>
              </div>
            </div>
          </div>

          <div className="block-form">
            <div className="inputs">
              <div className="input">
                <input type="text" name="cleaning" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Клининг
                </div>
              </div>
              <div className="input">
                <input type="text" name="transfer" onChange={changeInput}/>
                <div className="placeholder" onClick={focusInput}>
                  Трансфер
                </div>
              </div>
            </div>
          </div>

          <div className="buttons">
            <button type="submit" className="button">Зарегистрировать</button>
          </div>

        </form>
        {avatar && <CropAvatar avatar={avatar} setAvatar={setAvatar} requestUser={newAvatar} setRequestUser={setNewAvatar}/>}

      </div>
      </>
    : null}
    <Buttons access={access} setAccess={setAccess} refresh={refresh} setRefresh={setRefresh} requestUser={requestUser} setRequestUser={setRequestUser} setRefreshRequired={setRefreshRequired}/>
    <Back/>
    </>
  );
}
export default AddGuest;
