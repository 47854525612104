import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'

import Service from './../Service'
const service = new Service()

function Payments({access, setAccess, refresh, setRefresh, requestUser, setRequestUser, setRefreshRequired, setShowPayments, payments}) {

  const navigate = useNavigate()

  const block = useRef(null);
  const list = useRef(null);
  const payments_list = useRef(null);

  const [showCommInfo, setShowCommInfo] = useState(null)

  function preventDefault(e){
    e.preventDefault();
  }

  const touchMove = (e) => {
    block.current.style.height = window.innerHeight - e.touches[0].clientY + 'px';
  }

  const touchEnd = (e) => {
    if (block.current.offsetTop < document.querySelector('#guests').getBoundingClientRect().top ){
      block.current.style.transition = '.4s';
      block.current.style.height = '100%'
      setTimeout(() => {
        block.current.style.transition = 'transform .4s';
      }, 400)
    }else if (block.current.offsetTop > document.querySelector('#guests').getBoundingClientRect().top
        & block.current.offsetTop < window.innerHeight - ((window.innerHeight - document.querySelector('#guests').getBoundingClientRect().height))){
      block.current.style.transition = '.4s';
      block.current.style.height = window.innerHeight - document.querySelector('#guests').getBoundingClientRect().top + 'px';
      setTimeout(() => {
        block.current.style.transition = 'transform .4s';
      }, 400)
    }else if (block.current.offsetTop > window.innerHeight - ((window.innerHeight - document.querySelector('#guests').offsetHeight) / 2)){
      close()
    }
  }

  const close = (e) => {
    block.current.style.transform = "translateY(100vh)";
    document.body.classList.remove('lock');
    document.body.removeEventListener('touchmove', preventDefault);
    setTimeout(() => {setShowPayments(false)}, 300);
  }

  useEffect(() => {
    if (block){
      document.body.classList.add('lock');
      document.body.addEventListener('touchmove', preventDefault, { passive: false });
      list.current.addEventListener('touchmove', function(e){e.stopPropagation()}, false);
      block.current.style.height = window.innerHeight - document.querySelector('#guests').getBoundingClientRect().top + 'px';
      return function () {
        document.body.removeEventListener('touchmove', preventDefault);
        list.current.removeEventListener('touchmove', function(e){e.stopPropagation()});
      }
    }
  }, [block])

  return (
    <>
    <div className="comments" ref={block}>
      <div className="commentsHeader" onTouchMove={touchMove} onTouchEnd={touchEnd}>
        <div>
          <div className="tongue"></div>
        </div>
        <div>
          <div>Архив платежей <span>{payments.length - 1}</span></div>
          <div><img src={require('./images/close.svg').default} alt="" onClick={close}/></div>
        </div>
      </div>
      <div className="payments_list" ref={list}>
        <div ref={payments_list}>
          {payments.slice(1).map((p, idx) =>
            <div className="item" key={idx}>

              <div className="info">
                <div className="active pb10" onClick={showCommInfo === p.id ? () => setShowCommInfo(null) : () => setShowCommInfo(p.id)}>
                  <div className="flex pt5">
                    {p.status ?
                      <div className="value">
                        Оплачен {p.date}
                      </div>
                    :
                      <div className="value">
                        Текущий платёж до {p.date}
                      </div>
                    }

                    <div className="rating"></div>
                    <div className="arrow">
                      <img src={require('./images/arrow-down2.svg').default} alt="" style={showCommInfo === p.id ? {rotate: "180deg"} : {rotate: "0deg"}}/>
                    </div>
                  </div>
                  <div className="flex mt10 pb10">
                    <div className="flex-item">
                      <div className="placeholder">Сумма к оплате</div>
                      <div className="value">{p.sum} ₽</div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">Аренда</div>
                      <div className="value">{p.rent} ₽</div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">Комм. услуги</div>
                      <div className="value">{p.communal.sum} ₽</div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">Долг</div>
                      <div className="value">{p.duty} ₽</div>
                    </div>
                  </div>
                  {!p.status &&
                    <div className="button">
                      Подтвердить оплату
                    </div>
                  }
                </div>
                <div className={showCommInfo === p.id ? "bt comm" : "bt comm close"}>
                  <div className="flex mt10">
                    <div className="value">
                      Коммунальные платежи за {p.communal.date}
                    </div>
                  </div>
                  <div className="grid mt10 mb10">
                    <div>
                    </div>
                    <div>
                      <div className="placeholder">В пред. месяце</div>
                    </div>
                    <div>
                      <div className="placeholder">Текущее</div>
                    </div>
                    <div>
                      <div className="placeholder">Разница</div>
                    </div>
                    <div>
                      <div className="placeholder">Сумма</div>
                    </div>
                    <div>
                      <div className="placeholder">Холодная вода</div>
                    </div>
                    <div>
                      <div className="value">{payments[1] ? payments[1].communal.coldwater : '-'}</div>
                    </div>
                    <div>
                      <div className="value">{p.communal.coldwater}</div>
                    </div>
                    <div>
                      <div className="value">{payments[1] ? p.communal.coldwater - payments[1].communal.coldwater : '-'}</div>
                    </div>
                    <div>
                      <div className="value"></div>
                    </div>

                    <div>
                      <div className="placeholder">Горячая вода</div>
                    </div>
                    <div>
                      <div className="value">{payments[1] ? payments[1].communal.hotwater : '-'}</div>
                    </div>
                    <div>
                      <div className="value">{p.communal.hotwater}</div>
                    </div>
                    <div>
                      <div className="value">{payments[1] ? p.communal.hotwater - payments[1].communal.hotwater : '-'}</div>
                    </div>
                    <div>
                      <div className="value"></div>
                    </div>

                    <div>
                      <div className="placeholder">Электроэнергия</div>
                    </div>
                    <div>
                      <div className="value">{payments[1] ? payments[1].communal.electricity : '-'}</div>
                    </div>
                    <div>
                      <div className="value">{p.communal.electricity}</div>
                    </div>
                    <div>
                      <div className="value">{payments[1] ? p.communal.electricity - payments[1].communal.electricity : '-'}</div>
                    </div>
                    <div>
                      <div className="value"></div>
                    </div>

                    <div>
                      <div className="placeholder">Газоснабжение</div>
                    </div>
                    <div>
                      <div className="value">{payments[1] ? payments[1].communal.gas : '-'}</div>
                    </div>
                    <div>
                      <div className="value">{p.communal.gas}</div>
                    </div>
                    <div>
                      <div className="value">{payments[1] ? p.communal.gas - payments[1].communal.gas : '-'}</div>
                    </div>
                    <div>
                      <div className="value"></div>
                    </div>

                  </div>
                  <div className="flex space-between pb10 bb">
                    <img src={require("./images/gallery.svg").default} alt=""/>
                    <div className="value">
                      Сумма к оплате: {p.communal.sum} ₽
                    </div>
                  </div>
                </div>
              </div>

            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
}
export default Payments;
