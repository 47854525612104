import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from "react-router";

import Buttons from './Buttons'
import Back from './Back'
import Guests from './Guests'
import Payments from './Payments'

import Service from '../Service'
const service = new Service();

function Apartment({access, setAccess, refresh, setRefresh, requestUser, setRequestUser, setRefreshRequired, apartments, setApartments}) {

  const [apartment, setApartment] = useState(null)
  const { apartment_id } = useParams()
  const [activeStatus, setActiveStatus] = useState('active')
  const [loading, setLoading] = useState(false)
  const [history, setHistory] = useState(null)

  const [showPassportInfo, setShowPassportInfo] = useState(false)
  const [showCommInfo, setShowCommInfo] = useState(false)

  const [removeGuest, setRemoveGuest] = useState(false)
  const [showGuests, setShowGuests] = useState(false)
  const [showPayments, setShowPayments] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    if (access){
      if (removeGuest) {
        service.removeGuest(apartment_id, {'Authorization': `Bearer ${access}`}).then(function (result) {
          if (result.status === 200){
            setRefreshRequired(false);
            setApartment(result.data.result);
            if (!result.data.result.active) {
              setActiveStatus('disactive')
            };
          }else if (result.status === 401){
            setRefreshRequired(true);
          }
        });
      }
    }
  }, [access, removeGuest])

  useEffect(() => {
    if (access){
      if (!apartment) {
        service.getApartment(apartment_id, {'Authorization': `Bearer ${access}`}).then(function (result) {
          if (result.status === 200){
            setRefreshRequired(false);
            setApartment(result.data.result);
            if (!result.data.result.active) {
              setActiveStatus('disactive')
            };

          }else if (result.status === 401){
            setRefreshRequired(true);
          }
        });
      }
    }
  }, [access, apartment])

  useEffect(() => {
    if (access){
      if (loading){
        let active;
        if (activeStatus === 'active'){
          active = false
        }else{
          active = true
        }
        service.changeApartment(apartment_id, {active: active}, {'Authorization': `Bearer ${access}`}).then(function (result) {
          if (result.status === 200){
            setRefreshRequired(false);
            setApartment(result.data.result);
            setApartments([...apartments.filter((n) => n.id < result.data.result.id), result.data.result, ...apartments.filter((n) => n.id > result.data.result.id)]);
            if (!result.data.result.active) {
              setActiveStatus('disactive')
            }else{
              setActiveStatus('active')
            };
            setLoading(false)
          }else if (result.status === 401){
            setRefreshRequired(true);
          }
        });
      }
    }
  }, [access, loading])

  const activeApartment = (e) => {
    setLoading(true);
  }

  return (
    <>
    {apartment ?
      <>
      <div className="location-list">
        <div className="location">
          {apartment.address.address}
        </div>
      </div>
      <div className="block">
        <div className="block-top">
          <div className="title">
            <span><div className={!apartment.active ? "red" : apartment.status === 'Свободна' ? "green" : apartment.status === 'Бронь' ? "yellow" : apartment.status === 'Занята' ? "red" : null}></div>Квартира №{apartment.number}</span>
          </div>
          <div className={"on_off " + activeStatus} onClick={activeApartment}>
            <div></div>
            <div className="point"></div>
            <div></div>
          </div>
          {!apartment.active ?
          <div className="button gray">
            Н/Д
          </div>
          : apartment.status === 'Свободна' ?
            <div className="button gray" onClick={() => navigate('/apartment/'+apartment_id+'/add/guest')}>
              Заселить
            </div>
            :
            <div className="button gray" onClick={() => setRemoveGuest(true)}>
              Выселить
            </div>
          }

        </div>

        {apartment.history.length > 0 ?
          <>
          <div className="items guests">
            <div className="item">
              <div className="label">
                {apartment.status === 'Свободна' ?
                  "Последний проживающий"
                : "Текущий проживающий"}

              </div>
              <div className="info">
                <div className="active" onClick={showPassportInfo ? () => setShowPassportInfo(false) : () => setShowPassportInfo(true)}>
                  <div className="flex pt5">
                    <div className="value">
                      {apartment.history[0].user.last_name} {apartment.history[0].user.name} {apartment.history[0].user.middle_name}
                    </div>
                    <div className="rating">
                      4/5 <img src={require('./images/star.svg').default} alt=""/>
                    </div>
                    <div className="arrow">
                      <img src={require('./images/arrow-down2.svg').default} alt="" style={showPassportInfo ? {rotate: "180deg"} : {rotate: "0deg"}}/>
                    </div>
                  </div>
                  <div className="phone" onClick={(e) => e.stopPropagination()}>
                    <a href={"tel:"+apartment.history[0].user.phone}>
                      {apartment.history[0].user.phone}
                    </a>
                  </div>
                  <div className="flex mt10 pb5">
                    <div className="flex-item">
                      <div className="placeholder">
                        Дата заезда
                      </div>
                      <div className="value">
                        {apartment.history[0].date_start}
                      </div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">
                        Дата след. платежа
                      </div>
                      <div className="value">
                        {apartment.payments[0].date}
                      </div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">
                        Дата выезда
                      </div>
                      <div className="value">
                        {apartment.history[0].date_end ? apartment.history[0].date_end : '—'}
                      </div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">
                        Залог
                      </div>
                      <div className="value">
                        {apartment.history[0].deposit ? apartment.history[0].deposit : '0'} ₽
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex bt pt5">
                  <div className="flex-item flex">
                    <div className="placeholder">
                      Взрослые:&nbsp;
                    </div>
                    <div className="value">
                      {apartment.history[0].adults}
                    </div>
                  </div>
                  <div className="flex-item flex">
                    <div className="placeholder">
                      Дети:&nbsp;
                    </div>
                    <div className="value">
                      {apartment.history[0].babies}
                    </div>
                  </div>
                  <div className="flex-item flex">
                    <div className="placeholder">
                      Животные:&nbsp;
                    </div>
                    <div className="value">
                      {apartment.history[0].animals}
                    </div>
                  </div>
                </div>
                <div className="flex mt10 pb10">
                  <div className="flex-item">
                    <img src={require("./images/passport.svg").default} alt=""/>
                    <div className="placeholder white">
                      Паспорт
                    </div>
                  </div>
                  <div className="flex-item">
                    <img src={require("./images/document.svg").default} alt=""/>
                    <div className="placeholder white">
                      Договор
                    </div>
                  </div>
                </div>
                <div className={showPassportInfo ? "passport-info bb" : "passport-info bb close"}>
                  <div className="label pb10">
                    Паспортные данные
                  </div>
                  <div className="flex">
                    <div className="flex-item">
                      <div className="placeholder">Серия и номер</div>
                      <div className="value">{apartment.history[0].user.number ? apartment.history[0].user.number : '—'}</div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">Код подразделения</div>
                      <div className="value">{apartment.history[0].user.subdivision ? apartment.history[0].user.subdivision : '—'}</div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">Дата выдачи</div>
                      <div className="value">{apartment.history[0].user.issue_date ? apartment.history[0].user.issue_date : '—'}</div>
                    </div>
                  </div>
                  <div className="flex mt10">
                    <div className="flex-item">
                      <div className="placeholder">Место рождения</div>
                      <div className="value">{apartment.history[0].user.birth_place ? apartment.history[0].user.birth_place : '—'}</div>
                    </div>
                  </div>
                  <div className="flex mt10">
                    <div className="flex-item">
                      <div className="placeholder">Кем выдан</div>
                      <div className="value">{apartment.history[0].user.issued_by ? apartment.history[0].user.issued_by : '—'}</div>
                    </div>
                  </div>
                  <div className="flex mt10 pb10">
                    <div className="flex-item">
                      <div className="placeholder">Регистрация</div>
                      <div className="value">{apartment.history[0].user.registration_address ? apartment.history[0].user.registration_address : '—'}</div>
                    </div>
                  </div>
                </div>
                {apartment.history.length > 1 ?
                  <div className="lastComment" id="guests" onClick={showGuests ? () => setShowGuests(false) : () => setShowGuests(true)}>
                    <div className="lastCommentHeader">
                      Предыдущие жильцы <span>{apartment.history.length - 1}</span>
                      <img src={require('./images/arrow-top-down.svg').default} alt=""/>
                    </div>
                    <div className="lastItem">
                      <div className="avatar">
                        <img src={apartment.history[1].user.avatar ? apartment.history[1].user.avatar : "https://backend.idlpro.ru/media/avatars/non/non-avatar.svg"} alt="" />
                      </div>
                      <div className="text">
                        {apartment.history[1].user.last_name} {apartment.history[1].user.name} {apartment.history[1].user.middle_name}
                      </div>
                    </div>
                  </div>
                : null}
              </div>
            </div>
          </div>

          {apartment.payments.length > 0 ?
          <div className="items payments">
            <div className="item">
              <div className="label">
                {apartment.status === 'Свободна' ?
                "Последний платёж"
                : "Текущий платёж"}
              </div>
              <div className="info">
                <div className="active pb10" onClick={showCommInfo ? () => setShowCommInfo(false) : () => setShowCommInfo(true)}>
                  <div className="flex pt5">
                    {apartment.payments[0].status ?
                      <div className="value">
                        Оплачен {apartment.payments[0].date}
                      </div>
                    :
                      <div className="value">
                        Текущий платёж до {apartment.payments[0].date}
                      </div>
                    }

                    <div className="rating"></div>
                    <div className="arrow">
                      <img src={require('./images/arrow-down2.svg').default} alt="" style={showCommInfo ? {rotate: "180deg"} : {rotate: "0deg"}}/>
                    </div>
                  </div>
                  <div className="flex mt10 pb10">
                    <div className="flex-item">
                      <div className="placeholder">Сумма к оплате</div>
                      <div className="value">{apartment.payments[0].sum} ₽</div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">Аренда</div>
                      <div className="value">{apartment.payments[0].rent} ₽</div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">Комм. услуги</div>
                      <div className="value">{apartment.payments[0].communal.sum} ₽</div>
                    </div>
                    <div className="flex-item">
                      <div className="placeholder">Долг</div>
                      <div className="value">{apartment.payments[0].duty} ₽</div>
                    </div>
                  </div>
                  {!apartment.payments[0].status &&
                    <div className="button">
                      Подтвердить оплату
                    </div>
                  }
                </div>
                <div className={showCommInfo ? "bt comm" : "bt comm close"}>
                  <div className="flex mt10">
                    <div className="value">
                      Коммунальные платежи за {apartment.payments[0].communal.date}
                    </div>
                  </div>
                  <div className="grid mt10 mb10">
                    <div>
                    </div>
                    <div>
                      <div className="placeholder">В пред. месяце</div>
                    </div>
                    <div>
                      <div className="placeholder">Текущее</div>
                    </div>
                    <div>
                      <div className="placeholder">Разница</div>
                    </div>
                    <div>
                      <div className="placeholder">Сумма</div>
                    </div>

                    <div>
                      <div className="placeholder">Холодная вода</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[1] ? apartment.payments[1].communal.coldwater : '-'}</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[0].communal.coldwater}</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[1] ? apartment.payments[0].communal.coldwater - apartment.payments[1].communal.coldwater : '-'}</div>
                    </div>
                    <div>
                      <div className="value"></div>
                    </div>

                    <div>
                      <div className="placeholder">Горячая вода</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[1] ? apartment.payments[1].communal.hotwater : '-'}</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[0].communal.hotwater}</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[1] ? apartment.payments[0].communal.hotwater - apartment.payments[1].communal.hotwater : '-'}</div>
                    </div>
                    <div>
                      <div className="value"></div>
                    </div>

                    <div>
                      <div className="placeholder">Электроэнергия</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[1] ? apartment.payments[1].communal.electricity : '-'}</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[0].communal.electricity}</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[1] ? apartment.payments[0].communal.electricity - apartment.payments[1].communal.electricity : '-'}</div>
                    </div>
                    <div>
                      <div className="value"></div>
                    </div>

                    <div>
                      <div className="placeholder">Газоснабжение</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[1] ? apartment.payments[1].communal.gas : '-'}</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[0].communal.gas}</div>
                    </div>
                    <div>
                      <div className="value">{apartment.payments[1] ? apartment.payments[0].communal.gas - apartment.payments[1].communal.gas : '-'}</div>
                    </div>
                    <div>
                      <div className="value"></div>
                    </div>

                  </div>
                  <div className="flex space-between pb10">
                    <img src={require("./images/gallery.svg").default} alt=""/>
                    <div className="value">
                      Сумма к оплате: {apartment.payments[0].communal.sum} ₽
                    </div>
                  </div>
                </div>
                {apartment.payments.length > 1 ?
                  <div className="lastComment bt pt10" onClick={showPayments ? () => setShowPayments(false) : () => setShowPayments(true)}>
                    <div className="lastCommentHeader">
                      Архив платежей <span>{apartment.payments.length - 1}</span>
                      <img src={require('./images/arrow-top-down.svg').default} alt=""/>
                    </div>
                    <div className="flex mt5">
                      <div className="value">
                        Оплачен {apartment.payments[1].date}
                      </div>
                    </div>
                    <div className="flex mt10">
                      <div className="flex-item">
                        <div className="placeholder">Сумма к оплате</div>
                        <div className="value">{apartment.payments[1].sum} ₽</div>
                      </div>
                      <div className="flex-item">
                        <div className="placeholder">Аренда</div>
                        <div className="value">{apartment.payments[1].rent} ₽</div>
                      </div>
                      <div className="flex-item">
                        <div className="placeholder">Комм. услуги</div>
                        <div className="value">{apartment.payments[1].communal.sum} ₽</div>
                      </div>
                      <div className="flex-item">
                        <div className="placeholder">Долг</div>
                        <div className="value">{apartment.payments[1].duty} ₽</div>
                      </div>
                    </div>
                  </div>
                : null}
              </div>
            </div>
          </div>
          : null}
          </>
        :
        <div className="null">
          <div className="apartments_null">История квартиры пустая</div>
          <div className="button" onClick={() => navigate('/apartment/'+apartment_id+'/add/guest')}>Заселить гостя</div>
        </div>}
      </div>
      </>
    : null}
    {showPayments ? <Payments access={access} setAccess={setAccess} refresh={refresh} setRefresh={setRefresh} requestUser={requestUser} setRequestUser={setRequestUser} setRefreshRequired={setRefreshRequired} setShowPayments={setShowPayments} payments={apartment.payments}/> : null}
    {showGuests ? <Guests access={access} setAccess={setAccess} refresh={refresh} setRefresh={setRefresh} requestUser={requestUser} setRequestUser={setRequestUser} setRefreshRequired={setRefreshRequired} setShowGuests={setShowGuests} history={apartment.history}/> : null}
    <Buttons access={access} setAccess={setAccess} refresh={refresh} setRefresh={setRefresh} requestUser={requestUser} setRequestUser={setRequestUser} setRefreshRequired={setRefreshRequired}/>
    <Back/>
    </>
  );
}
export default Apartment;
