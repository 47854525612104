import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'

import Service from './../Service'
const service = new Service()

function Guests({access, setAccess, refresh, setRefresh, requestUser, setRequestUser, setRefreshRequired, setShowGuests, history}) {

  const navigate = useNavigate()

  const block = useRef(null);
  const list = useRef(null);
  const guests_list = useRef(null);

  const [showPassportInfo, setShowPassportInfo] = useState(null)

  function preventDefault(e){
    e.preventDefault();
  }

  const touchMove = (e) => {
    block.current.style.height = window.innerHeight - e.touches[0].clientY + 'px';
  }

  const touchEnd = (e) => {
    if (block.current.offsetTop < document.querySelector('#guests').getBoundingClientRect().top ){
      block.current.style.transition = '.4s';
      block.current.style.height = '100%'
      setTimeout(() => {
        block.current.style.transition = 'transform .4s';
      }, 400)
    }else if (block.current.offsetTop > document.querySelector('#guests').getBoundingClientRect().top
        & block.current.offsetTop < window.innerHeight - ((window.innerHeight - document.querySelector('#guests').getBoundingClientRect().height))){
      block.current.style.transition = '.4s';
      block.current.style.height = window.innerHeight - document.querySelector('#guests').getBoundingClientRect().top + 'px';
      setTimeout(() => {
        block.current.style.transition = 'transform .4s';
      }, 400)
    }else if (block.current.offsetTop > window.innerHeight - ((window.innerHeight - document.querySelector('#guests').offsetHeight) / 2)){
      close()
    }
  }

  const close = (e) => {
    block.current.style.transform = "translateY(100vh)";
    document.body.classList.remove('lock');
    document.body.removeEventListener('touchmove', preventDefault);
    setTimeout(() => {setShowGuests(false)}, 300);
  }

  useEffect(() => {
    if (block){
      document.body.classList.add('lock');
      document.body.addEventListener('touchmove', preventDefault, { passive: false });
      list.current.addEventListener('touchmove', function(e){e.stopPropagation()}, false);
      block.current.style.height = window.innerHeight - document.querySelector('#guests').getBoundingClientRect().top + 'px';

      return function () {
        document.body.removeEventListener('touchmove', preventDefault);
        list.current.removeEventListener('touchmove', function(e){e.stopPropagation()});
      }
    }
  }, [block])

  return (
    <>
    <div className="comments" ref={block}>
      <div className="commentsHeader" onTouchMove={touchMove} onTouchEnd={touchEnd}>
        <div>
          <div className="tongue"></div>
        </div>
        <div>
          <div>Предыдущие жильцы <span>{history.length - 1}</span></div>
          <div><img src={require('./images/close.svg').default} alt="" onClick={close}/></div>
        </div>
      </div>
      <div className="guests_list" ref={list}>
        <div ref={guests_list}>
          {history.slice(1).map((h, idx) =>
            <div className="item" key={idx}>
              <div className={showPassportInfo === h.id ? "flex pb10 active" : "flex active"}>
                <div className="avatar">
                  <img src={h.user.avatar ? h.user.avatar : "https://backend.idlpro.ru/media/avatars/non/non-avatar.svg" } alt=""/>
                </div>
                <div className="info" onClick={showPassportInfo === h.id ? () => setShowPassportInfo(null) : () => setShowPassportInfo(h.id)}>
                  <div className="flex-item">
                    <div className="value" style={{maxWidth: 180}}>{h.user.last_name} {h.user.name} {h.user.middle_name}</div>
                    <div className="phone" onClick={(e) => e.stopPropagination()}>
                      <a href={"tel:"+h.user.phone}>
                        {h.user.phone}
                      </a>
                    </div>
                  </div>
                  <div className="flex-item">
                    <div className="">
                      <div className="placeholder">Дата заезда</div>
                      <div className="value">{h.date_start}</div>
                    </div>
                    <div className="mt5">
                      <div className="placeholder">Дата выезда</div>
                      <div className="value">{h.date_start}</div>
                    </div>
                  </div>
                  <div className="flex-item">
                    <div className="arrow">
                      <img src={require('./images/arrow-down2.svg').default} alt="" style={showPassportInfo === h.id ? {rotate: "180deg"} : {rotate: "0deg"}}/>
                    </div>
                    <div className="rating">
                      4/5 <img src={require('./images/star.svg').default} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div className={showPassportInfo === h.id ? "passport-info pt5" : "passport-info pt5 close"}>
                <div className="label pb5">
                  Паспортные данные
                </div>
                <div className="flex">
                  <div className="flex-item">
                    <div className="placeholder">Серия и номер</div>
                    <div className="value">{h.user.number ? h.user.number : '—'}</div>
                  </div>
                  <div className="flex-item">
                    <div className="placeholder">Код подразделения</div>
                    <div className="value">{h.user.subdivision ? h.user.subdivision : '—'}</div>
                  </div>
                  <div className="flex-item">
                    <div className="placeholder">Дата выдачи</div>
                    <div className="value">{h.user.issue_date ? h.user.issue_date : '—'}</div>
                  </div>
                </div>
                <div className="flex mt10">
                  <div className="flex-item">
                    <div className="placeholder">Место рождения</div>
                    <div className="value">{h.user.birth_place ? h.user.birth_place : '—'}</div>
                  </div>
                </div>
                <div className="flex mt10">
                  <div className="flex-item">
                    <div className="placeholder">Кем выдан</div>
                    <div className="value">{h.user.issued_by ? h.user.issued_by : '—'}</div>
                  </div>
                </div>
                <div className="flex mt10 pb10">
                  <div className="flex-item">
                    <div className="placeholder">Регистрация</div>
                    <div className="value">{h.user.registration_address ? h.user.registration_address : '—'}</div>
                  </div>
                </div>
                <div className="flex pb10">
                  <div className="flex-item">
                    <img src={require("./images/passport.svg").default} alt=""/>
                    <div className="placeholder white">
                      Паспорт
                    </div>
                  </div>
                  <div className="flex-item">
                    <img src={require("./images/document.svg").default} alt=""/>
                    <div className="placeholder white">
                      Договор
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
}
export default Guests;
